/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */

import { useState } from 'react';
import ConfirmationModal from '../confirmationModal';
import Pagination from '../uiElements/pagination';

const EmailsTable = ({
  emails,
  deleteEmail,
  setPage,
  setSearch,
  setPerPage,
}) => {
  const [deleteId, setDeleteid] = useState(false);
  const closeModal = () => {
    setDeleteid(false);
  };
  const deleteModal = (id) => {
    setDeleteid(id);
  };

  const confirmAction = () => {
    deleteEmail(deleteId);
    closeModal();
  };

  const tableHeadStyle = 'p-3 text-sm font-medium text-left';
  const tableDataStyle = 'p-2 text-xs font-medium text-left';

  return (
    <>
      <div className="mt-5 overflow-hidden bg-white rounded-lg shadow-xxl">
        <table className="w-full table-auto">
          <thead className="bg-themegray-50">
            <tr>
              <th className={tableHeadStyle}>Updated At</th>
              <th className={tableHeadStyle}>Email</th>
              <th className={tableHeadStyle}>Name</th>
              <th className={tableHeadStyle}>Mobile Phone</th>
              <th className={tableHeadStyle}>Company Name</th>
              <th className={tableHeadStyle}>Job Title</th>
              <th className={tableHeadStyle}>Host</th>
              <th className={`${tableHeadStyle} text-center`}>Action</th>
            </tr>
          </thead>
          <tbody>
            {emails?.data?.length > 0 && emails.data.map((signature, index) => (
              <tr
                className={`bg-emerald-200 ${index % 2 === 0
                  ? 'bg-gray-50' : ''}`}
                key={signature._id}
              >
                <td className={tableDataStyle}>{new Date(signature.updatedAt).toDateString()}</td>
                <td className={tableDataStyle}>{signature.email}</td>
                <td className={tableDataStyle}>
                  {signature.first_name}
                  {' '}
                  {signature.last_name}
                </td>
                <td className={tableDataStyle}>{signature.mobile_phone}</td>
                <td className={tableDataStyle}>{signature.company_name}</td>
                <td className={tableDataStyle}>{signature.job_title}</td>
                <td className={tableDataStyle}>
                  {signature.host_ids.length > 0
               && (
                 <>
                   visits:
                   {' '}
                   {signature.host_ids[0].visit_count}
                   {' '}
                   {'time\'s'}
                   <br />
                   Location:
                   {' '}
                   {signature.host_ids[0]?.geo?.country}
                   ,
                   {signature.host_ids[0]?.geo?.timezone}
                 </>
               )}
                </td>
                <td className={`${tableDataStyle} text-center`}>
                  <button onClick={() => deleteModal(signature._id)} type="button" className="p-2 text-white rounded-full fill-current focus:outline-none bg-themecolor">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                      <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Pagination
        setPage={setPage}
        setSearch={setSearch}
        setPerPage={setPerPage}
        emails={emails}
      />

      <ConfirmationModal
        confirmAction={confirmAction}
        isOpen={!!deleteId}
        closeModal={closeModal}
      />
    </>
  );
};

export default EmailsTable;
