import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import InputWithErr from '../../components/uiElements/inputWithErr';
import { apiRequest } from '../../utils/request';
import * as urls from '../../utils/urls';

export default function Settings() {
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    profile_picture: '',
    profile_picture_preview: '',
    profile_picture_file: '',
    company_logo: '',
    company_logo_preview: '',
    company_logo_file: '',
    company_url: '',
    banner: '',
    first_name: '',
    last_name: '',
    job_title: '',
    company_name: '',
    office_phone: '',
    mobile_phone: '',
    email: '',
    address: '',
    description: '',
    website: '',
    facebook_url: '',
    twitter_url: '',
    linkedin_url: '',
    instagram_url: '',
    cta_copy_preview: '',
    cta_copy_file: '',
    cta_copy: '',
    cta_url: '',
    cta_prefix: '',
  });

  const [errorObject, setErrorObject] = useState({
    profile_picture: null,
    profile_picture_file: null,
    company_logo: null,
    company_logo_file: null,
    company_url: null,
    banner: null,
    first_name: null,
    last_name: null,
    job_title: null,
    company_name: null,
    office_phone: null,
    mobile_phone: null,
    email: null,
    address: null,
    description: null,
    website: null,
    facebook_url: null,
    twitter_url: null,
    linkedin_url: null,
    instagram_url: null,
    cta_copy_file: null,
    cta_copy: null,
    cta_url: null,
    cta_prefix: null,
  });

  const changeHandler = ({
    target: {
      name, value, files, type
    }
  }) => {
    if (type === 'file') {
      const file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        setState({
          ...state,
          [`${[name]}_preview`]: reader.result,
          [`${name}_file`]: file,
        });
      };
    } else {
      setState({
        ...state,
        [name]: value,
      });
    }
  };

  function updateSettings() {
    apiRequest(urls.GET_SETTINGS)
      .then((res) => {
        if (res.data.success && res.data.data) {
          setState({
            ...state,
            ...res.data.data
          });
        } else if (res.data.message) {
          // toast.success(res.data.message);
        } else {
          toast.error('Something went wrong!');
        }
      })
      .catch((err) => {
        if (err.response?.data && err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error('Something went wrong!');
        }
      });
  }

  useEffect(() => {
    updateSettings();
  }, []);

  const formSubmitHandler = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('profile_picture', state.profile_picture_file);
      formData.append('company_logo', state.company_logo_file);
      formData.append('company_url', state.company_url);
      formData.append('banner', state.banner);
      formData.append('first_name', state.first_name);
      formData.append('last_name', state.last_name);
      formData.append('job_title', state.job_title);
      formData.append('company_name', state.company_name);
      formData.append('office_phone', state.office_phone);
      formData.append('mobile_phone', state.mobile_phone);
      formData.append('email', state.email);
      formData.append('address', state.address);
      formData.append('description', state.description);
      formData.append('website', state.website);
      formData.append('facebook_url', state.facebook_url);
      formData.append('twitter_url', state.twitter_url);
      formData.append('linkedin_url', state.linkedin_url);
      formData.append('instagram_url', state.instagram_url);
      formData.append('cta_copy', state.cta_copy_file);
      formData.append('cta_url', state.cta_url);
      formData.append('cta_prefix', state.cta_prefix);

      const res = await apiRequest(urls.UPDATE_SETTINGS, formData);
      if (res.data.success) {
        setErrorObject({
          email: null,
          password: null,
        });
        setLoading(false);
        updateSettings();
        // toast.success(res.data.message);
      } else if (res.data.message) {
        toast.error(res.data.message);
      } else {
        toast.error('Something went wrong!');
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.data) {
        if (error.response.data.message) {
          toast.error(error.response.data.message);
          setErrorObject({
            email: null,
            password: null,
          });
        }
        if (error.response.data.errors) {
          setErrorObject({
            ...error.response.data.errors,
          });
        }
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  return (
    <form onSubmit={formSubmitHandler}>
      <div className="mb-4 text-right">
        <button
          type="submit"
          className="btn-red"
        >
          {loading && (
          <svg className="w-5 h-5 mr-3 -ml-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
          </svg>
          )}
          Update Settings
        </button>
      </div>
      <div className="grid grid-cols-3 gap-2">
        <InputWithErr
          errorObject={errorObject}
          state={state}
          name="profile_picture"
          type="file"
          placeholder="Profile Picture"
          changeHandler={changeHandler}
        />
        <InputWithErr
          errorObject={errorObject}
          state={state}
          name="company_logo"
          type="file"
          placeholder="Company Logo"
          changeHandler={changeHandler}
        />
        <InputWithErr
          errorObject={errorObject}
          state={state}
          name="email"
          type="email"
          placeholder="Email"
          changeHandler={changeHandler}
        />
        <InputWithErr
          errorObject={errorObject}
          state={state}
          name="company_url"
          type="text"
          placeholder="Company Url"
          changeHandler={changeHandler}
        />
        <InputWithErr
          errorObject={errorObject}
          state={state}
          name="banner"
          type="text"
          placeholder="Banner"
          changeHandler={changeHandler}
        />
        <InputWithErr
          errorObject={errorObject}
          state={state}
          name="first_name"
          type="text"
          placeholder="First Name"
          changeHandler={changeHandler}
        />
        <InputWithErr
          errorObject={errorObject}
          state={state}
          name="last_name"
          type="text"
          placeholder="Last Name"
          changeHandler={changeHandler}
        />
        <InputWithErr
          errorObject={errorObject}
          state={state}
          name="job_title"
          type="text"
          placeholder="Job Title"
          changeHandler={changeHandler}
        />
        <InputWithErr
          errorObject={errorObject}
          state={state}
          name="company_name"
          type="text"
          placeholder="Company Name"
          changeHandler={changeHandler}
        />
        <InputWithErr
          errorObject={errorObject}
          state={state}
          name="office_phone"
          type="text"
          placeholder="Office Phone"
          changeHandler={changeHandler}
        />
        <InputWithErr
          errorObject={errorObject}
          state={state}
          name="mobile_phone"
          type="text"
          placeholder="Mobile Phone"
          changeHandler={changeHandler}
        />
        <InputWithErr
          errorObject={errorObject}
          state={state}
          name="address"
          type="text"
          placeholder="Address"
          changeHandler={changeHandler}
        />
        <InputWithErr
          errorObject={errorObject}
          state={state}
          name="description"
          type="text"
          placeholder="description"
          changeHandler={changeHandler}
        />
        <InputWithErr
          errorObject={errorObject}
          state={state}
          name="website"
          type="text"
          placeholder="Website"
          changeHandler={changeHandler}
        />
        <InputWithErr
          errorObject={errorObject}
          state={state}
          name="facebook_url"
          type="text"
          placeholder="Facebook Url"
          changeHandler={changeHandler}
        />
        <InputWithErr
          errorObject={errorObject}
          state={state}
          name="twitter_url"
          type="text"
          placeholder="Twitter Url"
          changeHandler={changeHandler}
        />
        <InputWithErr
          errorObject={errorObject}
          state={state}
          name="linkedin_url"
          type="text"
          placeholder="Linkedin Url"
          changeHandler={changeHandler}
        />
        <InputWithErr
          errorObject={errorObject}
          state={state}
          name="instagram_url"
          type="text"
          placeholder="Instagram Url"
          changeHandler={changeHandler}
        />
        <InputWithErr
          errorObject={errorObject}
          state={state}
          name="cta_copy"
          type="file"
          placeholder="CTA Image Url"
          changeHandler={changeHandler}
        />
        <InputWithErr
          errorObject={errorObject}
          state={state}
          name="cta_url"
          type="text"
          placeholder="CTA Link"
          changeHandler={changeHandler}
        />
        <InputWithErr
          errorObject={errorObject}
          state={state}
          name="cta_prefix"
          type="text"
          placeholder="CTA Prefix"
          changeHandler={changeHandler}
        />
      </div>
    </form>
  );
}
