import { Link } from 'react-router-dom';

const PageNotFound = () => (
  <div className="flex items-center justify-center h-screen p-10">
    <div className="flex-1 text-center">
      <h2 className="mb-8 text-6xl font-extrabold text-blue-600">Page Not Found</h2>
      <Link to="/" className="inline-flex items-center px-10 py-3 text-white rounded-lg bg-themecolor">
        Go Back
        {' '}
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="ml-3 bi bi-arrow-90deg-left" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z" />
        </svg>
      </Link>
    </div>
  </div>
);

export default PageNotFound;
