export const assetUrl = process.env.REACT_APP_ASSET_URL;
export const LOGIN = {
  method: 'POST',
  relativeUrl: 'auth/login',
};

export const GET_PROFILE = {
  method: 'GET',
  relativeUrl: 'profile',
};

export const GET_EMAILS = {
  method: 'GET',
  relativeUrl: 'email',
};

export const DELETE_EMAIL = {
  method: 'DELETE',
  relativeUrl: 'email/',
};

export const EXPORT_EMAILS = {
  method: 'GET',
  relativeUrl: 'email/export-all',
};

export const GET_SETTINGS = {
  method: 'GET',
  relativeUrl: 'settings',
};

export const UPDATE_SETTINGS = {
  method: 'POST',
  relativeUrl: 'settings',
};

export const GET_STATUS = {
  method: 'GET',
  relativeUrl: 'status',
};

export const POST_IMAGE = {
  method: 'POST',
  relativeUrl: 'postimage',
};
