import { Transition } from '@headlessui/react';
import PropTypes from 'prop-types';

const ModalLayout = ({ isOpen, children }) => (
  <Transition
    show={isOpen}
    enter="ease-out duration-300"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="ease-in duration-200"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    <div
      className="fixed inset-0 z-10 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center md:flex sm:block sm:p-0">
        <div
          className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"
          aria-hidden="true"
        />

        <Transition.Child
            // show={isOpen}
          enter="transition ease-out duration-150 transform"
          enterFrom="opacity-0 scale-90"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-100 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-90"
        >
          <div className="inline-block w-full overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle">
            {children}
          </div>
        </Transition.Child>
      </div>
    </div>
  </Transition>
);

ModalLayout.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ModalLayout;
