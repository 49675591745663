/* eslint-disable react/prop-types */
/* eslint-disable eqeqeq */
import { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import TabButton from '../../components/uiElements/tabButton';
import UserProfile from '../../components/userProfile';
import { apiRequest } from '../../utils/request';
import * as urls from '../../utils/urls';
import Dashboard from './dashboard';
import Emails from './emails';
import LoginAccountModal from './loginModal';
import Settings from './settings';

const Admin = ({ match }) => {
  const [auth, setAuth] = useState(null);

  const setUser = (data) => {
    setAuth(data);
    window.location.reload();
  };

  useEffect(() => {
    apiRequest(urls.GET_PROFILE)
      .then((res) => {
        if (res.data.success) {
          setAuth(res.data.data);
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }, []);

  return (
    <div>
      {auth && (
        <div className="flex max-w-full gap-3 px-5 py-8 m-auto lg:px-16">
          {/* admin card */}
          <UserProfile />
          {/* content */}
          <div className="flex-1">
            <div className="flex gap-1 p-5 mb-5 text-center bg-white rounded-md shadow-xxl">
              <TabButton link={`${match.path}/dashboard`} label="Dashboard" />
              <TabButton link={`${match.path}/emails`} label="Emails" />
              <TabButton link={`${match.path}/settings`} label="Settings" />
            </div>

            <Switch>
              <Redirect exact from={`${match.path}`} to={`${match.path}/dashboard`} />
              <Route path={`${match.path}/dashboard`} component={Dashboard} />
              <Route path={`${match.path}/emails`} component={Emails} />
              <Route path={`${match.path}/settings`} component={Settings} />
              <Redirect exact from="*" to="/" />
            </Switch>
          </div>
        </div>
      )}
      <LoginAccountModal
        setUser={setUser}
        isOpen={!auth}
      />
    </div>
  );
};

export default Admin;
