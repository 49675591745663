import Cookies from 'js-cookie';
import {
  createRef, useEffect, useState
} from 'react';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import Congratulations from '../../components/congratulations';
import Footer from '../../components/footer';
import Header from '../../components/header';
import MobileView from '../../components/mobileView';
import Signature from '../../components/signature';
import TemplateController from '../../components/templateController';
import { downloadSignatureFromRef } from '../../utils/commonFunctions';
import { apiRequest } from '../../utils/request';
import * as urls from '../../utils/urls';

function App() {
  // create signature ref
  const signatureTableRef = createRef();

  const [defaultValues, setDefaultValues] = useState({});
  const [loadingDefault, setloadingDefault] = useState(true);
  const [selectedTemplateId, setSelectedTemplateId] = useState(0);
  const [state, setState] = useState({
    profile_picture: '',
    profile_pictureInvalid: false,
    company_logoInvalid: false,
    company_logo: '',
    second_company_logo: '',
    company_url: '',
    second_company_url: '',
    banner: '',
    first_name: '',
    last_name: '',
    job_title: '',
    company_name: '',
    office_phone: '',
    mobile_phone: '',
    email: '',
    address: '',
    description: '',
    website: '',
    facebook_url: '',
    twitter_url: '',
    linkedin_url: '',
    instagram_url: '',
    theme_color: 'FD5001',
    text_color: '252525',
    link_color: '3269E5',
    font_face: 'Sans-serif',
    font_size: 50,
    compliment_book: true,
    cta_copy: '',
    cta_prefix: '',
    cta_url: '',
  });
  const [successPage, setSuccessPage] = useState(false);

  // set default values to state once
  useEffect(() => {
    setState({ ...state, ...defaultValues });
  }, [defaultValues]);

  // get update state from chache
  useEffect(() => {
    let cacheState = Cookies.get('state');
    if (cacheState) {
      cacheState = JSON.parse(Cookies.get('state'));
    }

    // get defaut state from admin settings
    setloadingDefault(true);
    apiRequest(urls.GET_SETTINGS)
      .then((res) => {
        if (res.data.success && res.data.data) {
          setDefaultValues({
            ...res.data.data
          });
          if (cacheState) {
            setState({
              ...cacheState,
              cta_copy: res.data.data.cta_copy,
              cta_prefix: res.data.data.cta_prefix
            });
          } else {
            setState({
              ...state,
              cta_copy: res.data.data.cta_copy,
              cta_prefix: res.data.data.cta_prefix
            });
          }
        } else if (res.data.message) {
          toast.error(res.data.message);
        } else {
          toast.error('Something went wrong!');
        }
        setloadingDefault(false);
      })
      .catch((err) => {
        setloadingDefault(false);
        if (err.response?.data && err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error('Something went wrong!');
        }
        if (cacheState) {
          setState({
            ...cacheState,
          });
        }
      });
  }, []);

  // update cache function
  function updateCache() {
    Cookies.set('state', JSON.stringify(state));
  }

  // update cache on state change
  useEffect(() => {
    updateCache();
  }, [state]);

  // clear cache on state change
  function clearCache() {
    Cookies.remove('state');
  }

  // validator
  const [validator] = useState(new SimpleReactValidator({
    element: (message) => <div className="mt-2 text-xs font-medium text-themecolor">{message}</div>,
  }));

  const showSuccessPage = (type) => {
    setSuccessPage(type);
  };

  // clear all input fields
  const clearAllInputFields = () => {
    setState({
      ...state,
      theme_color: 'FD5001',
      text_color: '252525',
      link_color: '3269E5',
      font_face: 'Sans-serif',
      font_size: 50,
      compliment_book: true,
      ...defaultValues
    });
    clearCache();
  };

  const hideSuccessPage = () => {
    setSuccessPage(false);
  };

  // const isDefaultEmpty = useMemo(() => {
  //   const fields = [
  //     'profile_picture',
  //     'company_logo',
  //     'second_company_logo',
  //     'company_url',
  //     'second_company_url',
  //     'banner',
  //     'first_name',
  //     'last_name',
  //     'job_title',
  //     'company_name',
  //     'office_phone',
  //     'mobile_phone',
  //     'email',
  //     'address',
  //     'description',
  //     'website',
  //     'facebook_url',
  //     'twitter_url',
  //     'linkedin_url',
  //     'instagram_url',
  //     'cta_url',
  //   ];
  //   // is should set default value?
  //   let shouldReplace = true;
  //   fields.forEach((field) => {
  //     if (state[field]) {
  //       shouldReplace = false;
  //     }
  //   });
  //   // eslint-disable-next-line no-console
  //   // console.log(shouldReplace, 'should provide preview state');
  //   return shouldReplace;
  // }, [state]);

  // state/event handlers
  const selectTemplate = (templateId) => {
    setSelectedTemplateId(templateId);
  };

  const changeHandler = ({
    target: {
      name, value, type, files
    }
  }) => {
    let newValue = value;
    if (type === 'color' || name.match(/_color$/g)) {
      newValue = newValue.replace(/#/g, '');
    }

    if (type === 'file') {
      setState({
        ...state,
        [`${name}Invalid`]: false,
      });
      const file = files[0];
      const fileSize = file.size / 1024;
      const reader = new FileReader();
      reader.readAsDataURL(file);

      const formData = new FormData();
      formData.append(name, file);
      apiRequest(urls.POST_IMAGE, formData)
        .then((res) => {
          if (res.status === 200 && fileSize <= 3000) {
            setState({
              ...state,
              [name]: res.data.cdn,
              [`${name}Invalid`]: false,
            });
          } else {
            setState({
              ...state,
              [`${name}Invalid`]: true,
              [name]: '',
            });
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            // eslint-disable-next-line no-console
            toast.error(err?.response?.data?.message);
          } else {
            // eslint-disable-next-line no-console
            toast.error('something went wront!');
          }
        });

      reader.onloadend = () => {
        setState({
          ...state,
          [`${[name]}_preview`]: reader.result,
        });
      };
    } else {
      setState({
        ...state,
        [name]: newValue,
      });
    }
  };

  const checkHandler = ({ target: { name } }) => {
    setState((prevState) => ({
      ...state,
      [name]: !prevState[name],
    }));
  };

  // downloadSignature From Ref
  const downloadSignature = () => {
    downloadSignatureFromRef(signatureTableRef);
  };

  // new state included default value
  // const previewState = { ...state, ...defaultValues };
  // const printState = isDefaultEmpty ? previewState : state;
  const printState = state;

  return (
    <>
      <div className="pl-80">
        <TemplateController
          state={state}
          selectedTemplateId={selectedTemplateId}
          selectTemplate={selectTemplate}
          changeHandler={changeHandler}
          checkHandler={checkHandler}
          validator={validator}
        />
        <div className="grid content-between min-h-screen px-16 py-8">
          <Header />
          {loadingDefault ? (
            <div className="text-center">
              <svg className="mr-3 -ml-1 w-28 h-28 text-themecolor animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
              </svg>
            </div>
          ) : (
            <Signature
              ref={signatureTableRef}
              state={printState}
              selectedTemplateId={selectedTemplateId}
            />
          )}
          <Footer
            showSuccessPage={showSuccessPage}
            state={state}
            setState={setState}
            validator={validator}
            clearAll={clearAllInputFields}
            ref={signatureTableRef}
          />
        </div>
      </div>

      {successPage && (
        <Congratulations
          download={downloadSignature}
          type={successPage}
          hideSuccessPage={hideSuccessPage}
        />
      )}
      {/* mobile view */}
      <MobileView />
    </>
  );
}

export default App;
