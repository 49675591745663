import PropTypes from 'prop-types';

const SocialsLinksTable = ({ state, linkColor }) => {
  const linkGap = 10;
  const anchorStyle = {
    display: 'inline-block',
    padding: '5px',
    lineHeight: '0',
    background: linkColor,
  };
  const iconImgWidth = 12;
  return (
    <table
      cellPadding="0"
      cellSpacing="0"
      style={{
        verticalAlign: '-webkit-baseline-middle',
        display: 'inline-block',
      }}
    >
      <tbody>
        <tr style={{ textAlign: 'center' }}>
          {state.facebook_url ? (
            <>
              <td>
                <a
                  target="_blank"
                  href={state.facebook_url}
                  style={anchorStyle}
                  rel="noreferrer"
                >
                  <img width={iconImgWidth} style={{ margin: 0, padding: 0 }} src="https://i.postimg.cc/xjRDNH4R/facebook.png" alt="facebook" />
                </a>
              </td>
              <td width={linkGap}><div /></td>
            </>
          ) : null}
          {state.linkedin_url ? (
            <>
              <td>
                <a
                  target="_blank"
                  href={state.linkedin_url}
                  style={anchorStyle}
                  rel="noreferrer"
                >
                  <img width={iconImgWidth} style={{ margin: 0, padding: 0 }} src="https://i.postimg.cc/Pr5GWJGk/linkedin.png" alt="linkedin" />
                </a>
              </td>
              <td width={linkGap}><div /></td>
            </>
          ) : null}
          {state.instagram_url ? (
            <>
              <td>
                <a
                  target="_blank"
                  href={state.instagram_url}
                  style={anchorStyle}
                  rel="noreferrer"
                >
                  <img width={iconImgWidth} style={{ margin: 0, padding: 0 }} src="https://i.postimg.cc/BnjdzzQY/instagram.png" alt="instagram" />
                </a>
              </td>
              <td width={linkGap}><div /></td>
            </>
          ) : null}
          {state.twitter_url ? (
            <td>
              <a
                target="_blank"
                href={state.twitter_url}
                style={anchorStyle}
                rel="noreferrer"
              >
                <img width={iconImgWidth} style={{ margin: 0, padding: 0 }} src="https://i.postimg.cc/5N7hnJ1Q/twitter.png" alt="twitter" />
              </a>
            </td>
          ) : null}
        </tr>
      </tbody>
    </table>
  );
};

SocialsLinksTable.propTypes = {
  state: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ).isRequired,
  linkColor: PropTypes.string.isRequired,
};

export default SocialsLinksTable;
