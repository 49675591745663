import { useTranslation } from 'react-i18next';

const Head = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-themegray-50">
      <div className="px-4 py-2 border-gray-200 border-b-1">
        <span className="inline-block w-1.5 h-1.5 mr-1.5 bg-red-400 rounded-full" />
        <span className="inline-block w-1.5 h-1.5 mr-1.5 bg-yellow-300 rounded-full" />
        <span className="inline-block w-1.5 h-1.5 bg-green-400 rounded-full" />
      </div>
      <div className="py-4 px-7">
        <p className="mb-0.5 text-xs font-medium text-gray-400">
          {t('To')}
          {' '}
          :
          {' '}
          <strong className="font-semibold">{t('Your Recipient')}</strong>
        </p>
        <p className="text-xs font-medium text-gray-400">
          {t('Subject')}
          {' '}
          :
          {' '}
          <strong className="font-semibold">{t('Create your own Email Signature')}</strong>
        </p>
      </div>
    </div>
  );
};

export default Head;
