/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import StatusCard from '../../components/statusCard';
import { apiRequest } from '../../utils/request';
import * as urls from '../../utils/urls';

export default function Dashboard() {
  const [status, setStatus] = useState(null);

  useEffect(() => {
    apiRequest(urls.GET_STATUS)
      .then((res) => {
        if (res.data.success) {
          setStatus(res.data.data);
        }
      });
  }, []);

  const total_emails = (status && status.total_emails) || 0;
  const total_hosts = (status && status.total_hosts) || 0;
  return (
    <div className="grid grid-cols-2 gap-2">
      <StatusCard label="Total Emails" value={total_emails} />
      <StatusCard label="Total Visitors" value={total_hosts} />
    </div>
  );
}
