import PropTypes from 'prop-types';

const Switch = ({ onChange, value, name }) => (
  <div className="switchCheckBox">
    <input
      className="absolute appearance-none"
      id={name}
      checked={value}
      name={name}
      value={value}
      onChange={onChange}
      type="checkbox"
    />
    <label className="rounded-full" htmlFor={name} />
  </div>
);

Switch.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
};

export default Switch;
