import { toast } from 'react-toastify';

export const fontScaleEnum = {
  SMALL: 'Small',
  MEDIUM: 'Medium',
  LARGE: 'Large',
};
export function getLabel(number, enums = fontScaleEnum) {
  if (number < 40) {
    return { label: enums.SMALL, num: 10 };
  } if (number > 40 && number < 70) {
    return { label: enums.MEDIUM, num: 14 };
  } if (number > 70) {
    return { label: enums.LARGE, num: 18 };
  }
  return { label: enums.MEDIUM, num: 14 };
}

export function downloadSignatureFromRef(ref) {
  const html = ref.current?.outerHTML;
  if (!html) {
    toast.error('please select a signature first!');
  } else {
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      `data:text/plain;charset=utf-8,${encodeURIComponent(
        html,
      )}`,
    );
    element.setAttribute('download', 'email-signature.html');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
}

export function copyHtmlFromRef(ref) {
  const html = ref.current?.outerHTML;
  if (!html) {
    return toast.error('please select a signature first!');
  }

  const input = document.createElement('textarea');
  input.innerHTML = html;
  document.body.appendChild(input);
  input.select();
  const result = document.execCommand('copy');
  document.body.removeChild(input);
  return result;
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function download(path, filename) {
  // Create a new link
  const anchor = document.createElement('a');
  anchor.href = path;
  anchor.download = filename;

  // Append to the DOM
  document.body.appendChild(anchor);

  // Trigger `click` event
  anchor.click();

  // Remove element from DOM
  document.body.removeChild(anchor);
}
