import Cookies from 'js-cookie';
import React from 'react';

export default function UserProfile() {
  // logout method
  const logOut = () => {
    Cookies.remove('auth_token');
    window.location.reload();
  };
  return (
    <div className="w-60">
      <div className="p-5 text-center bg-white rounded-md shadow-xxl">
        <div className="flex m-auto mb-5 rounded-full shadow-2xl bg-themecolor w-28 h-28">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="m-auto text-white fill-current bi bi-person-check" viewBox="0 0 16 16">
            <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
            <path fillRule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
          </svg>
        </div>

        <button onClick={logOut} type="button" className="w-full px-3 py-2 mt-8 mb-3 text-white rounded focus:outline-none bg-themecolor">
          Log Out
        </button>
      </div>
    </div>
  );
}
