/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import questionIcon from '../../images/question.png';
import { getLabel } from '../../utils/commonFunctions';
import { assetUrl } from '../../utils/urls';

const Input = ({
  type,
  label,
  value,
  name,
  onChange,
  placeholder,
  question,
  className,
  selectOptions,
}) => {
  const { t } = useTranslation();
  const uniqueId = uuidv4();
  const fontScaleEnum = {
    SMALL: t('Small'),
    MEDIUM: t('Medium'),
    LARGE: t('Large'),
  };
  let inputField;
  if (type === 'select') {
    inputField = (
      <select
        className="w-full pb-3 text-base font-medium bg-transparent border-gray-500 outline-none border-b-1"
        name={name}
        id={name}
        value={value}
        onChange={onChange}
      >
        {selectOptions.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  } else if (type === 'color') {
    inputField = (
      <div className="relative">
        <span className="absolute text-base font-medium text-gray-400 transform -translate-y-1/2 left-2 top-1/2">
          #
        </span>
        <input
          className="w-full h-12 p-2 pl-8 text-base font-medium rounded-lg outline-none pr-14"
          type="text"
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
        <input
          className="absolute top-0 right-0 h-10 m-1 overflow-hidden rounded-lg cursor-pointer w-11"
          type={type}
          id={name}
          name={name}
          value={`#${value}`}
          onChange={onChange}
          placeholder={placeholder}
        />
      </div>
    );
  } else if (type === 'range') {
    inputField = (
      <>
        <Slider
          min={0}
          max={100}
          step={1}
          value={value}
          tooltip={false}
          handleLabel={getLabel(value, fontScaleEnum).label}
          onChange={(num) => onChange({ target: { value: num, name } })}
        />
      </>
    );
  } else if (type === 'file') {
    inputField = (
      <label className="inputImageLabel" htmlFor={name}>
        {value[`${name}_preview`] ? <img src={value[`${name}_preview`]} alt={name} /> : (
          <>
            {value[name] && <img src={assetUrl + value[name]} alt={name} />}
          </>
        )}
        <input id={name} type={type} name={name} onChange={onChange} className="mb-1 formControl" placeholder={placeholder} />
      </label>
    );
  } else {
    inputField = (
      <input
        className="w-full pb-3 text-xs font-medium bg-transparent border-gray-500 outline-none border-b-1"
        type={type}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    );
  }
  return (
    <div className={className}>
      <label
        className="flex justify-between mb-5 text-sm font-medium text-gray-900 align-middle"
        htmlFor={name}
      >
        {label}
        {' '}
        {question && (
          <>
            <div data-tip data-for={uniqueId} className="cursor-pointer relative">
              <img
                src={questionIcon}
                className="opacity-50 filter grayscale hover:opacity-100 hover:filter-none"
                alt="question"
              />
            </div>
            <ReactTooltip multiline className="multilinetip" id={uniqueId} type="dark" effect="solid">
              {question}
            </ReactTooltip>
          </>
        )}
      </label>
      {inputField}
    </div>
  );
};

Input.defaultProps = {
  type: 'text',
  placeholder: '',
  question: '',
  className: '',
  selectOptions: [],
};

Input.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  question: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selectOptions: PropTypes.arrayOf(PropTypes.string),
};

export default Input;
