/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import Cookies from 'js-cookie';

function getAuthToken() {
  const token = Cookies.get('auth_token');
  return token || '';
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: { authorization: `Bearer ${getAuthToken()}` },
});

export const apiRequest = (urls, params, query = null, responseType = null) => {
  let url = urls.relativeUrl;
  if (query) {
    url += query;
  }
  return instance({
    url,
    method: urls.method,
    data: params,
    responseType,
  });
};
