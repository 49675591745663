/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalVideo from 'react-modal-video';
import questionIcon from '../../images/question.png';
import questionfill from '../../images/warning.png';
import Input from '../uiElements/input';
import Title from '../uiElements/title';

const CustomSignatureImage = ({ state, changeHandler }) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const questionMsg = t('Image Upload Note');
  return (
    <div className="p-8 pb-0">
      <Title text={t('Upload Your Custom Signature Images')} className="mb-4" />
      <div className="flex items-start justify-between">
        <img
          src={questionfill}
          width="15"
          style={{ width: '15px' }}
          alt="questionfill"
          className="mr-1"
        />
        <p
          className="text-xs font-medium text-gray-400 mb-7"
        >
          {t('create cdn image by folowing this link')}
          {' '}
          {/* <a className="text-black" href="https://postimages.org/" target="_blank" rel="noopener noreferrer">https://postimages.org/</a> */}
        </p>
        <img
          onClick={() => setOpen(true)}
          src={questionIcon}
          className="ml-1 cursor-pointer relative opacity-50 filter grayscale hover:opacity-100 hover:filter-none"
          alt="question"
        />
        <ModalVideo channel="youtube" autoplay isOpen={isOpen} videoId="p-oqu1JcOZY" onClose={() => setOpen(false)} />
      </div>

      <div className="mb-7">
        <Input
          type="file"
          label={t('Profile picture')}
          question={questionMsg}
          value={state}
          name="profile_picture"
          onChange={changeHandler}
          placeholder={t('Profile Image URL')}
        />
        {state.profile_pictureInvalid && <div className="mt-2 text-xs font-medium text-themecolor">profile picture size exceed 3MB</div>}
      </div>
      <div className="mb-7">
        <Input
          type="file"
          label={t('Company logo')}
          question={questionMsg}
          value={state}
          name="company_logo"
          onChange={changeHandler}
          placeholder={t('Company Logo Image URL')}
        />
        {state.company_logoInvalid && <div className="mt-2 text-xs font-medium text-themecolor">company logo size exceed 3MB</div>}
      </div>
      {/* <Input
        className="mb-7"
        label="2nd Company logo"
        question={questionMsg}
        value={state.second_company_logo}
        name="second_company_logo"
        onChange={changeHandler}
        placeholder="2nd Company Logo Image URL"
      /> */}
      {/* <div className="mb-7">
        <Input
          label="Company Name"
          value={state.company_name}
          name="company_name"
          onChange={changeHandler}
          placeholder="Company Name Image"
        />
      </div> */}
      {/* <Input
        className="mb-7"
        label="Banner"
        question={questionMsg}
        value={state.banner}
        name="banner"
        onChange={changeHandler}
        placeholder="Banner Image URL"
      /> */}
    </div>
  );
};

CustomSignatureImage.propTypes = {
  state: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ).isRequired,
  changeHandler: PropTypes.func.isRequired,
};

export default CustomSignatureImage;
