/* eslint-disable react/prop-types */
import React from 'react';
import { assetUrl } from '../../utils/urls';

export default function InputWithErr({
  errorObject, state, name, changeHandler, type, placeholder
}) {
  return (
    <div>
      <label className="block mb-1 text-xs text-gray-500">{placeholder}</label>
      {type === 'file' ? (
        <label className="inputImageLabel" htmlFor={name}>
          {state[`${name}_preview`] ? <img src={state[`${name}_preview`]} alt={name} /> : (
            <>
              {state[name] && <img src={assetUrl + state[name]} alt={name} />}
            </>
          )}
          <input id={name} type={type} name={name} onChange={changeHandler} className="mb-1 formControl" placeholder={placeholder} />
        </label>
      ) : (
        <input type={type} name={name} value={state[name]} onChange={changeHandler} className="mb-1 formControl" placeholder={placeholder} />
      )}
      {errorObject[name] && <p className="srv-validation-message">{errorObject[name].msg}</p>}
    </div>
  );
}
