import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Admin from '../containers/Admin';
import HomePage from '../containers/HomePage';
import PageNotFound from '../containers/PageNotFound';

function App() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/admin" component={Admin} />
          <Route exact component={PageNotFound} />
        </Switch>
      </BrowserRouter>
      <ToastContainer autoClose={2000} position="top-center" />
    </>
  );
}

export default App;
