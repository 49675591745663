import { NavLink } from 'react-router-dom';

/* eslint-disable react/prop-types */
const TabButton = ({
  label, link
}) => (
  <NavLink
    to={link}
    activeClassName="border-themecolor bg-themecolor text-white shadow-md"
    className="border-1 transition-all focus:outline-none text-xs py-1.5 px-3 rounded-sm"
  >
    {label}
  </NavLink>
);
export default TabButton;
