/* eslint-disable react/prop-types */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import { useTranslation } from 'react-i18next';
import selectedIcon from '../../images/selected.png';
import t1 from '../../images/templates/template1.png';
import t2 from '../../images/templates/template2.png';
import t3 from '../../images/templates/template3.png';
import t4 from '../../images/templates/template4.png';
import Title from '../uiElements/title';

const templates = [
  { preview: t4, name: 'Template 1' },
  { preview: t1, name: 'Template 2' },
  { preview: t2, name: 'Template 3' },
  { preview: t3, name: 'Template 4' },
];

// selectedTemplateId == index

const SelectTemplateTabContent = ({
  selectedTemplateId,
  selectTemplate,
}) => {
  const checkedIconClass = 'transition scale-0 duration-300 delay-75 absolute z-10 transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2';
  const overlayClass = 'transition duration-500 absolute top-0 left-0 z-0 w-full h-full bg-gray-900 opacity-0';

  const { t } = useTranslation();
  return (
    <div className="p-8 pb-0">
      <Title text={t('Select your Template')} className="mb-7" />
      {templates.map((template, index) => (
        <div key={index} className="mb-4" onClick={() => selectTemplate(index)}>
          <h4 className="mb-4 text-base font-medium text-gray-900">{template.name}</h4>
          <div className="relative overflow-hidden transition duration-300 ease-in-out cursor-pointer rounded-xl hover:shadow-xxl" key={index}>
            <img src={template.preview} alt="preview" />

            <img src={selectedIcon} alt="selected" className={`${selectedTemplateId == index ? `${checkedIconClass} scale-100` : checkedIconClass}`} />
            <div className={selectedTemplateId == index
              ? `${overlayClass} opacity-60` : overlayClass}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default SelectTemplateTabContent;
