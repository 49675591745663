/* eslint-disable react/prop-types */
import Cookies from 'js-cookie';
import { useState } from 'react';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import ModalLayout from '../../components/uiElements/modalLayout';
import { apiRequest } from '../../utils/request';
import { LOGIN } from '../../utils/urls';

const LoginModal = ({ isOpen, setUser }) => {
  const [validator] = useState(new SimpleReactValidator());
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    email: '',
    password: '',
  });

  const [errorObject, setErrorObject] = useState({
    email: null,
    password: null,
  });

  const changeHandler = ({ target: { name, value } }) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();

    if (validator.allValid()) {
      setLoading(true);
      try {
        const res = await apiRequest(LOGIN, state);
        if (res.data.success) {
          setErrorObject({
            email: null,
            password: null,
          });
          setLoading(false);
          // toast.success(res.data.message);
          Cookies.set('auth_token', res.data.auth_token);
          setUser(res.data.data);
        } else if (res.data.message) {
          toast.error(res.data.message);
        } else {
          toast.error('Something went wrong!');
        }
      } catch (error) {
        setLoading(false);
        if (error.data && error.data.message) {
          toast.error(error.data.message);
        } else if (error.response && error.response.data) {
          if (error.response.data.message) {
            toast.error(error.response.data.message);
            setErrorObject({
              email: null,
              password: null,
            });
          }
          if (error.response.data.errors) {
            setErrorObject({
              ...error.response.data.errors,
            });
          }
        } else {
          toast.error('Something went wrong!');
        }
      }

      validator.hideMessages();
    } else {
      setState({ ...state });
      validator.showMessages();
    }
  };

  return (
    <ModalLayout isOpen={isOpen}>
      <form onSubmit={formSubmitHandler}>
        <div className="p-5 pt-5 pb-4 bg-white lg:px-20 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 p-2 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
              <svg className="w-6 h-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                className="text-lg font-medium leading-6 text-gray-900"
                id="modal-title"
              >
                Login As Admin
              </h3>
              <div className="mt-6">
                <div className="mb-3">
                  <input type="email" name="email" value={state.email} onChange={changeHandler} className="mb-1 formControl w-80" placeholder="Email" />
                  {validator.message('Email', state.email, 'required|email')}
                  {errorObject.email && <p className="srv-validation-message">{errorObject.email.msg}</p>}
                </div>

                <div className="mb-3">
                  <input type="password" name="password" value={state.password} onChange={changeHandler} className="mb-1 formControl w-80" placeholder="Password" />
                  {validator.message('Password', state.password, 'required')}
                  {errorObject.password && <p className="srv-validation-message">{errorObject.password.msg}</p>}
                </div>
              </div>
              <div className="mb-6 text-right">
                <button
                  type="submit"
                  className="btn-red sm:ml-3"
                >
                  {loading && (
                    <svg className="w-5 h-5 mr-3 -ml-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                    </svg>
                  )}
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ModalLayout>
  );
};
export default LoginModal;
