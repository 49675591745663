import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Input from '../uiElements/input';
import Title from '../uiElements/title';

const fontFaceArray = [
  'Serif',
  'Sans-serif',
  'Monospace',
  'Cursive',
  'Fantasy',
  'MS',
];

const StyleYourSignature = ({ state, changeHandler }) => {
  const { t } = useTranslation();
  return (
    <div className="p-8 pb-0">
      <Title text={t('Stylize Your Signature')} className="mb-7" />

      <Input
        className="mb-7"
        question={t('select_theme_color_interrogation_text')}
        label={t('theme color')}
        value={state.theme_color}
        name="theme_color"
        onChange={changeHandler}
        type="color"
      />
      <Input
        className="mb-7"
        question={t('select_text_color_interrogation_text')}
        label={t('text color')}
        value={state.text_color}
        name="text_color"
        onChange={changeHandler}
        type="color"
      />
      <Input
        className="mb-7"
        question={t('select_icon_color_interrogation_text')}
        label={t('link color')}
        value={state.link_color}
        name="link_color"
        onChange={changeHandler}
        type="color"
      />
      <Input
        className="mb-7"
        question={t('select_font_color_interrogation_text')}
        label={t('Font')}
        value={state.font_face}
        name="font_face"
        onChange={changeHandler}
        selectOptions={fontFaceArray}
        type="select"
      />
      {/* <Input
        className="mb-7"
        question={t('select_font_size_color_interrogation_text')}
        label={t('Font Size')}
        value={state.font_size}
        name="font_size"
        onChange={changeHandler}
        type="range"
      /> */}
    </div>
  );
};

StyleYourSignature.propTypes = {
  state: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ).isRequired,
  changeHandler: PropTypes.func.isRequired,
};

export default StyleYourSignature;
