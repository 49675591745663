import PropTypes from 'prop-types';
import { useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import ModalVideo from 'react-modal-video';
import complimentIcon from '../../images/compliment.png';
import detailsIcon from '../../images/details.png';
import editIcon from '../../images/edit.png';
import templatesIcon from '../../images/templates.png';
import uploadImageIcon from '../../images/upload-image.png';
import ComplimentBook from './complimentBook';
import CustomSignatureImage from './customSignatureImage';
import SelectTemplateTabContent from './selectTemplateTabContent';
import SignatureDetails from './signatureDetails';
import StyleYourSignature from './styleYourSignature';
import TemplateControllerTabItem from './templateControllerTabItem';

const tabs = [
  { icon: templatesIcon, label: '1' },
  { icon: uploadImageIcon, label: '2' },
  { icon: detailsIcon, label: '3' },
  { icon: editIcon, label: '4' },
  { icon: complimentIcon, label: '5' },
];

function TemplateController({
  state,
  selectedTemplateId,
  selectTemplate,
  changeHandler,
  checkHandler,
  validator,
}) {
  const [activeTab, setActiveTab] = useState(0);

  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <div className="fixed top-0 left-0 grid h-screen grid-rows-auto1Fr bg-themegray-50 w-80">
        <div
          className={`grid grid-cols-${tabs.length} justify-items-stretch bg-themegray-100 templateControllerTab`}
        >
          {tabs.map((tab, index) => (
            <TemplateControllerTabItem
            // eslint-disable-next-line react/no-array-index-key
              key={index}
              currentIndex={index}
              active={activeTab}
              setActiveTab={setActiveTab}
              icon={tab.icon}
              label={tab.label}
            />
          ))}
        </div>
        <Scrollbars>
          <div className="mt-2">
            <button onClick={() => setOpen(true)} type="button" className="p-3 w-11/12 rounded-sm text-gray-600 bg-gray-200 text-xs block mx-auto font-semibold">
              {t('create your signature email tutorial button')}
            </button>
          </div>
          <ModalVideo channel="youtube" autoplay isOpen={isOpen} videoId="p-oqu1JcOZY" onClose={() => setOpen(false)} />
          {activeTab === 0 && (
          <SelectTemplateTabContent
            selectedTemplateId={selectedTemplateId}
            selectTemplate={selectTemplate}
          />
          )}
          {activeTab === 1 && (
          <CustomSignatureImage state={state} changeHandler={changeHandler} />
          )}
          {activeTab === 2 && (
          <SignatureDetails validator={validator} state={state} changeHandler={changeHandler} />
          )}
          {activeTab === 3 && (
          <StyleYourSignature state={state} changeHandler={changeHandler} />
          )}
          {activeTab === 4 && (
          <ComplimentBook
            state={state}
            changeHandler={changeHandler}
            checkHandler={checkHandler}
          />
          )}
        </Scrollbars>
      </div>
    </>
  );
}

TemplateController.propTypes = {
  state: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ).isRequired,
  selectedTemplateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  selectTemplate: PropTypes.func.isRequired,
  changeHandler: PropTypes.func.isRequired,
  checkHandler: PropTypes.func.isRequired,
  validator: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default TemplateController;
