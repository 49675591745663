import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Input from '../uiElements/input';
import Title from '../uiElements/title';

const SignatureDetails = ({ state, changeHandler, validator }) => {
  const { t } = useTranslation();
  return (
    <div className="p-8 pb-0">
      <Title text={t('Enter Your Signature Details')} className="mb-7" />
      <div className="mb-7">
        <Input
          label={t('First Name')}
          value={state.first_name}
          name="first_name"
          onChange={changeHandler}
          placeholder="John"
        />
        {validator.message(t('First Name'), state.first_name, 'required')}
      </div>
      <div className="mb-7">
        <Input
          label={t('Last Name')}
          value={state.last_name}
          name="last_name"
          onChange={changeHandler}
          placeholder="Smith"
        />
        {validator.message(t('Last Name'), state.last_name, 'required')}
      </div>
      <Input
        className="mb-7"
        label={t('Job Title')}
        value={state.job_title}
        name="job_title"
        onChange={changeHandler}
        placeholder="CEO"
      />
      {/* <div className="mb-7">
      <Input
        label="Company Name"
        value={state.company_name}
        name="company_name"
        onChange={changeHandler}
        placeholder="Company Name"
      />
      {validator.message('Company Name', state.company_name, 'required')}
    </div> */}
      <div className="mb-7">
        <Input
          label={t('Company Url')}
          value={state.company_url}
          name="company_url"
          onChange={changeHandler}
          placeholder={t('Company Url (Optional)')}
        />
        {/* {validator.message(t('Company Url'), state.company_url, 'required')} */}
      </div>
      <Input
        className="mb-7"
        label={t('Office Phone Number')}
        value={state.office_phone}
        name="office_phone"
        onChange={changeHandler}
        placeholder="111 222 3333"
      />
      <div className="mb-7">
        <Input
          label={t('Mobile Phone Number')}
          value={state.mobile_phone}
          name="mobile_phone"
          onChange={changeHandler}
          placeholder="111 222 3333"
        />
        {validator.message(t('Mobile Phone Number'), state.mobile_phone, 'required')}
      </div>
      <div className="mb-7">
        <Input
          label="Email"
          value={state.email}
          name="email"
          onChange={changeHandler}
          placeholder="john.smith@gmail.com"
        />
        {validator.message('Email', state.email, 'required|email')}
      </div>
      <Input
        className="mb-7"
        label={t('Address')}
        value={state.address}
        name="address"
        onChange={changeHandler}
        placeholder={t('Address')}
      />
      <div className="mb-7">
        <Input
          label={t('Website URL')}
          value={state.website}
          name="website"
          onChange={changeHandler}
          placeholder={t('Website URL')}
        />
        {validator.message(t('Website URL'), state.website, 'required')}
      </div>
      <Title text={t('Enter Your Social Links')} className="mb-7" />
      <Input
        className="mb-7"
        label={t('Facebook Website URL')}
        value={state.facebook_url}
        name="facebook_url"
        onChange={changeHandler}
        placeholder={t('Facebook Website URL')}
      />
      <Input
        className="mb-7"
        label={t('Linkedin Website URL')}
        value={state.linkedin_url}
        name="linkedin_url"
        onChange={changeHandler}
        placeholder={t('Linkedin Website URL')}
      />
      <Input
        className="mb-7"
        label={t('Instagram Website URL')}
        value={state.instagram_url}
        name="instagram_url"
        onChange={changeHandler}
        placeholder={t('Instagram Website URL')}
      />
      <Input
        className="mb-7"
        label={t('Twitter Website URL')}
        value={state.twitter_url}
        name="twitter_url"
        onChange={changeHandler}
        placeholder={t('Twitter Website URL')}
      />
    </div>
  );
};

SignatureDetails.propTypes = {
  state: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ).isRequired,
  changeHandler: PropTypes.func.isRequired,
  validator: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default SignatureDetails;
