/* eslint-disable eqeqeq */
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import Head from './head';
import SignatureTableFour from './templates/signatureTableFour';
import SignatureTableOne from './templates/signatureTableOne';
import SignatureTableThree from './templates/signatureTableThree';
import SignatureTableTwo from './templates/signatureTableTwo';

const Signature = forwardRef(({ state, selectedTemplateId }, ref) => (
  <div className="my-8 overflow-hidden bg-white rounded-xl">
    <Head />
    <div className="grid px-3 py-10 place-items-center">
      {selectedTemplateId == 0 && <SignatureTableFour ref={ref} state={state} />}
      {selectedTemplateId == 1 && <SignatureTableOne ref={ref} state={state} />}
      {selectedTemplateId == 2 && <SignatureTableTwo ref={ref} state={state} />}
      {selectedTemplateId == 3 && <SignatureTableThree ref={ref} state={state} />}
    </div>
  </div>
));

Signature.propTypes = {
  state: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ).isRequired,
  selectedTemplateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default Signature;
