/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalVideo from 'react-modal-video';
import congrats from '../../images/congrats.png';

const Congratulations = ({ hideSuccessPage, type, download }) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="flex items-center justify-center min-h-screen fixed bg-white z-50 w-full h-screen left-0 top-0">
      <div className="max-w-full px-5 text-center bg-white lg:w-2/4 lg:px-10 rounded-xl py-14 shadow-xxl">
        <img className="m-auto" src={congrats} alt="congrats" />
        <h2 className="mb-2 text-2xl font-semibold text-black">{t('congratulations_title')}</h2>
        <p className="mb-3 text-lg text-black">{t('congratulations_text')}</p>
        <p className="mb-8 font-semibold text-black">{type === 'copy' && 'Source Code copied to your clipboard'}</p>

        <div className="flex flex-wrap justify-center gap-2">
          <button
            onClick={download}
            type="button"
            className="flex items-center px-5 py-4 text-lg font-medium text-white transition-all border-2 border-white hover:text-black hover:border-themecolor focus:outline-none rounded-xl bg-themecolor hover:bg-white"
          >
            {t('Download again')}
          </button>
          <button
            onClick={hideSuccessPage}
            type="button"
            className="flex items-center px-5 py-4 text-lg font-medium text-white transition-all border-2 border-white hover:text-black hover:border-themecolor focus:outline-none rounded-xl bg-themecolor hover:bg-white"
          >
            {t('Create another signature')}
          </button>

          <button onClick={() => setOpen(true)} type="button" className="flex items-center px-5 py-4 text-lg font-medium text-black transition-all border-2 hover:text-white focus:outline-none rounded-xl border-themecolor hover:bg-themecolor">{t('how to Use Signature Tutorial')}</button>
          <ModalVideo channel="youtube" autoplay isOpen={isOpen} videoId="pRkqc74g6ng" start="139"  onClose={() => setOpen(false)} />
          {/* <a
            href="mailto:?body=Hi%20there%2C%0A%0AI%20just%20created%20a%20brand%20new%20email%20signature%20using%20Esignature%20free%20Email%20Signature%20Template%20Generator%20tool.%0A%0AHere's%20a%20link%3A%0A%0Ahttp%3A%2F%2Fesignature.vercel.app%0A%0AIt%20should%20only%20take%20you%20a%20couple%20of%20minutes%20to%20create%20%E2%80%93%20and%20it's%20free!%0ASent%20from%20Mail%20for%20Windows%2010%0A"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center px-5 py-4 text-lg font-medium text-black transition-all border-2 hover:text-white focus:outline-none rounded-xl border-themecolor hover:bg-themecolor"
          >
            Share with friends
          </a> */}
        </div>
      </div>
    </div>
  );
};

Congratulations.propTypes = {
  type: PropTypes.string.isRequired,
  hideSuccessPage: PropTypes.func.isRequired,
  download: PropTypes.func.isRequired,
};

export default Congratulations;
