import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import questionIcon from '../../images/question.png';

const Title = ({ text, className, question }) => (
  <>
    <h3 className={`text-lg flex justify-between items-center leading-5 font-medium text-black ${className}`}>
      {text}
      {' '}
      {question && (
      <div className="cursor-pointer" id="compliment">
        <img
          src={questionIcon}
          className="opacity-50 min-w-min filter grayscale hover:opacity-100 hover:filter-none"
          alt="question"
        />
      </div>
      )}

    </h3>
    <ReactTooltip className="multilinetip" multiline id="compliment" type="dark" effect="solid">
      {question}
    </ReactTooltip>
  </>
);

Title.defaultProps = {
  className: '',
  question: '',
};

Title.propTypes = {
  text: PropTypes.string.isRequired,
  question: PropTypes.string,
  className: PropTypes.string,
};

export default Title;
