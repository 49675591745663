/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import { forwardRef, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import arrowgoback from '../../images/arrow-go-back.png';
import download from '../../images/download.png';
import {
  // copyHtmlFromRef,
  downloadSignatureFromRef
} from '../../utils/commonFunctions';

const Footer = forwardRef(
  ({
    clearAll, state, setState, showSuccessPage, validator
  }, ref) => {
    // const [copying, setCopying] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const termsAndConditionRef = useRef();

    const { t } = useTranslation();

    const downloadGeneratedSignature = async () => {
      if (!termsAndConditionRef.current.checked) {
        toast.error(t('terms and condition error msg'));
        return;
      }
      if (!validator.allValid()) {
        setState({ ...state });
        validator.showMessages();
        toast.error('Please provide valid details!');
      } else if (!state.email) {
        toast.error('First Name is required Please provide valid details!');
      } else {
        validator.hideMessages();
        try {
          setDownloading(true);
          await fetch(`${process.env.REACT_APP_BASE_URL}create-signature`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify(state),
          });
          setDownloading(false);
          downloadSignatureFromRef(ref);
          showSuccessPage('download');
        } catch (err) {
          setDownloading(false);
          downloadSignatureFromRef(ref);
          showSuccessPage('download');
        }
      }
    };

    // const copyGeneratedSignature = async () => {
    //   if (!validator.allValid()) {
    //     setState({ ...state });
    //     validator.showMessages();
    //     toast.error('Please provide valid inputs!');
    //   } else if (!state.email) {
    //     toast.error('First Name is required Please provide valid details!');
    //   } else {
    //     validator.hideMessages();
    //     try {
    //       setCopying(true);
    //       await fetch(`${process.env.REACT_APP_BASE_URL}create-signature`, {
    //         method: 'POST',
    //         headers: {
    //           'Content-Type': 'application/json',
    //           // 'Content-Type': 'application/x-www-form-urlencoded',
    //         },
    //         body: JSON.stringify(state),
    //       });
    //       setCopying(false);
    //       copyHtmlFromRef(ref);
    //       showSuccessPage('copy');
    //     } catch (err) {
    //       setCopying(false);
    //       copyHtmlFromRef(ref);
    //       showSuccessPage('copy');
    //     }
    //   }
    // };

    return (
      <div>
        <div className="text-right">
          <div className="checkBox mb-2 ml-auto inline-block">
            <input ref={termsAndConditionRef} className="mr-1" type="checkbox" id="terms" />
            <label htmlFor="terms">
              {t('i accept condition')}
              <a href="https://elogiar.livrodeelogios.com/compliments/tc" target="_blank" className="inline-block font-semibold underline hover:text-themecolor" rel="noreferrer">{t('terms and condition')}</a>
            </label>
          </div>
        </div>
        <div className="gap-1 flex flex-wrap items-center justify-between">
          <div
            onClick={clearAll}
            className="flex items-center text-sm font-medium text-gray-500 cursor-pointer"
          >
            <img className="mr-2.5" src={arrowgoback} alt="arrowgoback" />
            {t('Clear all input fields')}
          </div>
          <div className="flex">
            {/* <button type="button" onClick={() => copyGeneratedSignature(ref)}
          className="flex items-center xl:p-5 p-4 ml-auto mr-2 xl:text-lg text-sm
          font-medium text-white focus:outline-none rounded-xl bg-themecolor">
          {copying ? (
            <svg className="w-5 h-5 mr-3 -ml-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10"
              stroke="currentColor" strokeWidth="4" />
              <path className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014
              12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
               />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="mr-3 bi bi-clipboard" viewBox="0 0 16 16">
              <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0
              2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1
              1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
              <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0
              1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5
              0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
            </svg>
          )}
          {t('Copy Source Code')}
        </button> */}
            <button
              type="button"
              onClick={() => downloadGeneratedSignature(ref)}
              className="flex items-center xl:p-5 p-4 xl:text-lg text-sm font-medium text-white focus:outline-none rounded-xl bg-themecolor"
            >
              {downloading ? (
                <svg
                  className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
              ) : (
                <img className="mr-3" src={download} alt="download" />
              )}
              {t('Download Email Signature')}
            </button>
          </div>
        </div>
      </div>
    );
  }
);

Footer.propTypes = {
  state: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
  ).isRequired,
  setState: PropTypes.func.isRequired,
  clearAll: PropTypes.func.isRequired,
  showSuccessPage: PropTypes.func.isRequired,
  validator: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Footer;
