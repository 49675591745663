/* eslint-disable react/prop-types */
import ModalLayout from '../uiElements/modalLayout';

const ConfirmationModal = ({ isOpen, closeModal, confirmAction }) => (
  <ModalLayout isOpen={isOpen}>
    <div className="p-5 w-96">
      <h2 className="mb-12 text-lg font-medium">Confirm To Delete</h2>
      <div className="text-right">
        <button
          onClick={closeModal}
          type="button"
          className="btn-default sm:ml-3"
        >
          Cancel
        </button>
        <button
          onClick={confirmAction}
          type="button"
          className="btn-red sm:ml-3"
        >
          Confirm
        </button>
      </div>
    </div>
  </ModalLayout>
);
export default ConfirmationModal;
