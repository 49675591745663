/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Input from '../uiElements/input';
import Switch from '../uiElements/switch';

const ComplimentBook = ({ state, checkHandler, changeHandler }) => {
  const { t } = useTranslation();
  return (
    <div className="p-8 pb-0">
      {/* <Title question={questionMsg} text="Compliment Book" className="mb-7" /> */}
      <p className="mb-8 text-sm text-gray-500">
        {t('compliment text')}
      </p>

      <div className="flex items-center justify-between mb-8">
        <h6 className="text-sm font-medium text-gray-900">
          {t('Add Livro de Elogios Digital Button')}
        </h6>

        <Switch
          value={state.compliment_book}
          name="compliment_book"
          onChange={checkHandler}
        />
      </div>

      <Input
        className="mb-8"
        label={t('Text before button')}
        value={state.description}
        name="description"
        onChange={changeHandler}
        placeholder={t('Text before button')}
      />

      {/* <Input
        className="mb-8"
        label="Custom CTA"
        question={questionMsg}
        value={state.cta_copy}
        name="cta_copy"
        onChange={changeHandler}
        placeholder="CTA Image URL"
      /> */}
      <Input
        label={t('Custom CTA Link')}
        question={t('custom_cta_link_interrogation')}
        value={state.cta_url}
        name="cta_url"
        onChange={changeHandler}
        placeholder="URL"
      />
      <p className="text-xs break-all text-gray-500 mt-3">
        {state.cta_prefix}
        <span className="font-semibold text-red-500">{state.cta_url || 'URL'}</span>
      </p>
    </div>
  );
};

ComplimentBook.propTypes = {
  state: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ).isRequired,
  changeHandler: PropTypes.func.isRequired,
  checkHandler: PropTypes.func.isRequired,
};

export default ComplimentBook;
