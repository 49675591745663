/* eslint-disable react/prop-types */
import React from 'react';

export default function StatusCard({ label, value }) {
  return (
    <div className="px-3 text-center bg-white rounded-md shadow-sm py-7">
      <h2 className="mb-5 text-2xl font-medium text-gray-600">{label}</h2>
      <div className="text-5xl font-extrabold text-themecolor">{value}</div>
    </div>
  );
}
