import PropTypes from 'prop-types';
import { fontScaleEnum, getLabel } from '../../../utils/commonFunctions';

const UserInfoMarkup = ({ state, textColor }) => {
  const fontSizeLabel = getLabel(state.font_size);
  let headingFontSize = '16px';
  let paragraphFontSize = '14px';
  if (fontSizeLabel.label === fontScaleEnum.SMALL) {
    headingFontSize = '14px';
    paragraphFontSize = '10px';
  } else if (fontSizeLabel.label === fontScaleEnum.LARGE) {
    headingFontSize = '18px';
    paragraphFontSize = '14px';
  } else {
    headingFontSize = '16px';
    paragraphFontSize = '12px';
  }
  return (
    <>
      <h3
        style={{
          margin: '0px',
          fontSize: headingFontSize,
          color: textColor,
          fontFamily: state.font_face,
        }}
      >
        <span>{state.first_name}</span>
        <span>&nbsp;</span>
        <span>{state.last_name}</span>
      </h3>
      <p
        style={{
          margin: '0px',
          color: textColor,
          fontSize: paragraphFontSize,
          lineHeight: '20px',
          fontFamily: state.font_face,
        }}
      >
        <span>{state.job_title}</span>
      </p>
      {state.company_name && (
        <p
          style={{
            marginTop: '10px',
            marginBottom: '0',
            // fontWeight: 500,
            // color: textColor,
            // fontSize: paragraphFontSize,
            // lineHeight: '20px',
            // fontFamily: state.font_face,
          }}
        >
          <img style={{ maxWidth: '100px' }} src={state.company_name} alt="" />
          {/* <span>{state.company_name}</span> */}
        </p>
      )}
    </>
  );
};

UserInfoMarkup.propTypes = {
  state: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ).isRequired,
  textColor: PropTypes.string.isRequired,
};

export default UserInfoMarkup;
