/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { fontScaleEnum, getLabel } from '../../../utils/commonFunctions';
import LineSeperator from './LineSeperator';

const AddressTable = ({ state, themeColor, textColor }) => {
  const fontSizeLabel = getLabel(state.font_size);
  let buttonFontSize = '14px';
  if (fontSizeLabel.label === fontScaleEnum.SMALL) {
    buttonFontSize = '14px';
  } else if (fontSizeLabel.label === fontScaleEnum.LARGE) {
    buttonFontSize = '18px';
  } else {
    buttonFontSize = '16px';
  }

  return (
    <table
      cellPadding="0"
      cellSpacing="0"
      style={{
        verticalAlign: '-webkit-baseline-middle',
        fontFamily: state.font_face,
      }}
    >
      <tbody>
        {state.office_phone ? (
          <tr height="25" style={{ verticalAlign: 'middle' }}>
            <td width="30" style={{ verticalAlign: 'middle' }}>
              <table
                cellPadding="0"
                cellSpacing="0"
                style={{
                  verticalAlign: '-webkit-baseline-middle',
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ verticalAlign: 'bottom' }}>
                      <span
                        style={{
                          display: 'block',
                        }}
                      >
                        <img
                          width="12"
                          style={{ background: themeColor }}
                          src="https://i.postimg.cc/SsjJZjJB/phone-icon.png"
                          alt="phone"
                        />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td style={{ padding: '0px', color: textColor }}>
              <a
                href={`tel:${state.office_phone}`}
                style={{
                  textDecoration: 'none',
                  color: textColor,
                  fontSize: '12px',
                }}
              >
                <span>{state.office_phone}</span>
              </a>
            </td>
          </tr>
        ) : null}
        {state.mobile_phone ? (
          <tr height="25" style={{ verticalAlign: 'middle' }}>
            <td width="30" style={{ verticalAlign: 'middle' }}>
              <table
                cellPadding="0"
                cellSpacing="0"
                style={{
                  verticalAlign: '-webkit-baseline-middle',
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ verticalAlign: 'bottom' }}>
                      <span
                        style={{
                          display: 'block',
                        }}
                      >
                        <img
                          width="12"
                          style={{ background: themeColor }}
                          src="https://i.postimg.cc/Jhpscj43/mobile-icon.png"
                          alt="smartphone"
                        />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td style={{ padding: '0px', color: textColor }}>
              <a
                href={`tel:${state.mobile_phone}`}
                style={{
                  textDecoration: 'none',
                  color: textColor,
                  fontSize: '12px',
                }}
              >
                <span>{state.mobile_phone}</span>
              </a>
            </td>
          </tr>
        ) : null}
        {state.email ? (
          <tr height="25" style={{ verticalAlign: 'middle' }}>
            <td width="30" style={{ verticalAlign: 'middle' }}>
              <table
                cellPadding="0"
                cellSpacing="0"
                style={{
                  verticalAlign: '-webkit-baseline-middle',
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ verticalAlign: 'bottom' }}>
                      <span
                        style={{
                          display: 'block',
                        }}
                      >
                        <img
                          width="12"
                          style={{ background: themeColor }}
                          src="https://i.postimg.cc/76cbjDzf/inbox.png"
                          alt="mail"
                        />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td style={{ padding: '0px' }}>
              <a
                href={`mailto:${state.email}`}
                style={{
                  textDecoration: 'none',
                  color: textColor,
                  fontSize: '12px',
                }}
              >
                <span>{state.email}</span>
              </a>
            </td>
          </tr>
        ) : null}
        {state.website ? (
          <tr height="25" style={{ verticalAlign: 'middle' }}>
            <td width="30" style={{ verticalAlign: 'middle' }}>
              <table
                cellPadding="0"
                cellSpacing="0"
                style={{
                  verticalAlign: '-webkit-baseline-middle',
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ verticalAlign: 'bottom' }}>
                      <span
                        style={{
                          display: 'block',
                        }}
                      >
                        <img
                          width="12"
                          style={{ background: themeColor }}
                          src="https://i.postimg.cc/5yCjVG5p/link-icon.png"
                          alt="external-link"
                        />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td style={{ padding: '0px' }}>
              <a
                href={state.website}
                style={{
                  textDecoration: 'none',
                  color: textColor,
                  fontSize: '12px',
                }}
              >
                <span>{state.website}</span>
              </a>
            </td>
          </tr>
        ) : null}
        {state.address ? (
          <tr height="25" style={{ verticalAlign: 'middle' }}>
            <td width="30" style={{ verticalAlign: 'middle' }}>
              <table
                cellPadding="0"
                cellSpacing="0"
                style={{
                  verticalAlign: '-webkit-baseline-middle',
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ verticalAlign: 'bottom' }}>
                      <span
                        style={{
                          display: 'block',
                        }}
                      >
                        <img
                          width="12"
                          style={{ background: themeColor }}
                          src="https://i.postimg.cc/mhWk2w84/marker.png"
                          alt="map-pin"
                        />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td style={{ padding: '0px' }}>
              <span style={{ fontSize: '12px', color: textColor }}>
                {state.address}
              </span>
            </td>
          </tr>
        ) : null}

        {state.compliment_book && (
        <>
          {(state.cta_url || state.description) && (
          <tr>
            <td colSpan="2">
              <LineSeperator themeColor={themeColor} />
            </td>
          </tr>
          )}
          {state.description && (
            <tr>
              <td colSpan="2">
                <p style={{ fontSize: '12px', fontWeight: 'bold' }}>{state.description}</p>
              </td>
            </tr>
          )}
          {state.cta_url && (
          <>
            <tr height="10">
              <td colSpan="2" />
            </tr>
            <tr height="40">
              <td colSpan="2">
                <a
                  style={{
                    display: 'inline-block',
                    // backgroundColor: '#fd5001',
                    // color: '#ffffff',
                    // padding: '10px',
                    // borderRadius: '10px',
                    // lineHeight: '0',
                    // fontSize: buttonFontSize,
                  }}
                  href={`${state.cta_url}${state.cta_prefix}`}
                >
                  <img width="200" style={{ maxWidth: '200px' }} src={`${process.env.REACT_APP_ASSET_URL}${state.cta_copy}`} alt="" />
                </a>
              </td>
            </tr>
          </>
          )}
        </>
        )}
      </tbody>
    </table>
  );
};

AddressTable.propTypes = {
  state: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ).isRequired,
  textColor: PropTypes.string.isRequired,
  themeColor: PropTypes.string.isRequired,
};

export default AddressTable;
