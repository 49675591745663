import { useTranslation } from 'react-i18next';
import logo from '../../images/logo.png';

export default function MobileView() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col justify-center items-center mobileView fixed left-0 top-0 lg:hidden h-screen w-full bg-gray-200 px-5">
      <img className="mb-4" style={{ maxWidth: '250px' }} src={logo} alt="logo" />
      <p className="text-gray-700 text-lg font-medium text-center">{t('mobile device error message')}</p>
    </div>
  );
}
