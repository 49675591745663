import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import EmailsTable from '../../components/emailsTable';
import { download } from '../../utils/commonFunctions';
import { apiRequest } from '../../utils/request';
import * as urls from '../../utils/urls';

export default function Emails() {
  const [emails, setEmails] = useState([]);
  const [page, setPage] = useState('');
  const [perPage, setPerPage] = useState('');
  const [search, setSearch] = useState('');

  const clearFilterEmail = () => {
    setPage('');
    setSearch('');
    setPerPage('');
  };

  const exportALlEmails = () => {
    apiRequest(urls.EXPORT_EMAILS, null, null, 'blob')
      .then((res) => {
        // Create an object URL
        const url = URL.createObjectURL(res.data);

        // Download file
        download(url, 'signature.csv');

        // Release the object URL
        URL.revokeObjectURL(url);
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  const updateEmails = () => {
    const params = `?page=${page}&perPage=${perPage}&search=${search}`;
    apiRequest(urls.GET_EMAILS, null, params)
      .then((res) => {
        if (res.data.success) {
          setEmails(res.data);
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  useEffect(() => {
    updateEmails();
  }, [page, search, perPage]);

  const deleteEmail = (id) => {
    apiRequest(urls.DELETE_EMAIL, null, id)
      .then((res) => {
        if (res.data.success) {
          clearFilterEmail();
          updateEmails();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        if (err.response.data) {
          toast.error(err.response.data.message);
        } else {
          toast.error('Something went wrong!');
        }
      });
  };

  return (
    <>
      <div className="text-right">
        <button
          onClick={exportALlEmails}
          type="button"
          className="px-5 py-3 text-xs font-medium text-white rounded-md focus:outline-none bg-themecolor"
        >
          Export All Emails
        </button>
      </div>
      <EmailsTable
        setPage={setPage}
        setSearch={setSearch}
        setPerPage={setPerPage}
        deleteEmail={deleteEmail}
        emails={emails}
      />
    </>
  );
}
