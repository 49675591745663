import { useTranslation } from 'react-i18next';
import logo from '../../images/logo.png';

const Header = () => {
  const { i18n, t } = useTranslation();

  const selectedLang = localStorage.getItem('i18nextLng');

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <div className="flex items-center justify-between">
      <img style={{ maxWidth: '250px' }} src={logo} alt="logo" />
      <div className="flex items-center justify-between">
        <p className="mr-2 text-xs font-semibold text-gray-500">{t('Choose Language')}</p>

        <div className="inline-block relative w-auto">
          <select
            value={selectedLang}
            onChange={(e) => changeLanguage(e.target.value)}
            className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-2 py-1 pr-6 rounded shadow leading-tight focus:outline-none focus:shadow-outline text-xs"
          >
            <option value="en">EN</option>
            <option value="pt">PT</option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
