import PropTypes from 'prop-types';
import React from 'react';
import { assetUrl } from '../../../utils/urls';
import AddressTable from './addressTable';
import LineSeperator from './LineSeperator';
import SocialsLinksTable from './socialsLinksTable';
import UserInfoMarkup from './userInfoMarkup';

const SignatureTableFour = React.forwardRef(({ state }, ref) => {
  const themeColor = `#${state.theme_color}`;
  const textColor = `#${state.text_color}`;
  const linkColor = `#${state.link_color}`;
  return (
    <div>
      <table
        ref={ref}
        cellPadding="0"
        cellSpacing="0"
        style={{
          verticalAlign: '-webkit-baseline-middle',
          fontFamily: state.font_face,
        }}
      >
        <tbody>
          <tr>
            <td>
              <table
                cellPadding="0"
                cellSpacing="0"
                style={{
                  verticalAlign: '-webkit-baseline-middle',
                  fontFamily: 'Lucida Sans Unicode',
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ verticalAlign: 'top' }}>
                      <UserInfoMarkup state={state} textColor={textColor} />
                      <LineSeperator themeColor={themeColor} />
                      <AddressTable
                        state={state}
                        textColor={textColor}
                        themeColor={themeColor}
                      />
                    </td>
                    <td width="25"><div /></td>
                    <td style={{ verticalAlign: 'top' }}>
                      <table
                        cellPadding="0"
                        cellSpacing="0"
                        style={{
                          verticalAlign: '-webkit-baseline-middle',
                          fontFamily: state.font_face,
                        }}
                      >
                        <tbody>
                          {state.profile_picture ? (
                            <tr>
                              <td>
                                <img
                                  src={assetUrl + state.profile_picture}
                                  role="presentation"
                                  width="120"
                                  style={{ maxWidth: '120px', display: 'block' }}
                                  alt="presentation"
                                />
                              </td>
                            </tr>
                          ) : null}
                          <tr>
                            <td height="30" />
                          </tr>
                          <tr style={{ textAlign: 'center' }}>
                            <td><SocialsLinksTable linkColor={linkColor} state={state} /></td>
                          </tr>
                          {state.company_logo ? (
                            <>
                              <tr>
                                <td height="30" />
                              </tr>
                              <tr>
                                <td>
                                  {state.company_url !== '' ? (
                                    <a href={state.company_url}>
                                      <img
                                        src={assetUrl + state.company_logo}
                                        role="presentation"
                                        width="130"
                                        style={{ width: '130px', maxWidth: '130px', display: 'block' }}
                                        alt="company_logo"
                                      />
                                    </a>
                                  ) : (
                                    <a href={state.website}>
                                      <img
                                        src={assetUrl + state.company_logo}
                                        role="presentation"
                                        width="130"
                                        style={{ width: '130px', maxWidth: '130px', display: 'block' }}
                                        alt="company_logo"
                                      />
                                    </a>
                                  )}
                                </td>
                              </tr>
                            </>
                          ) : null}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td height="30" />
          </tr>
        </tbody>
      </table>
    </div>
  );
});

SignatureTableFour.propTypes = {
  state: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ).isRequired,
};

export default SignatureTableFour;
