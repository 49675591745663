import PropTypes from 'prop-types';

const LineSeperator = ({ themeColor }) => (
  <table
    cellPadding="0"
    cellSpacing="0"
    style={{
      width: '100%',
      verticalAlign: '-webkit-baseline-middle',
    }}
  >
    <tbody>
      <tr>
        <td height="15" />
      </tr>
      <tr>
        <td
          direction="horizontal"
          height="1"
          style={{
            width: '100%',
            borderBottom: `1px solid ${themeColor}`,
            borderLeft: 'none',
            display: 'block',
          }}
        />
      </tr>
      <tr>
        <td height="15" />
      </tr>
    </tbody>
  </table>
);

LineSeperator.propTypes = {
  themeColor: PropTypes.string.isRequired,
};

export default LineSeperator;
