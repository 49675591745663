/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
const Pagination = ({
  emails,
  setPage,
  setSearch,
  setPerPage,
}) => {
  const {
    total, from, limit, page, to, totalPage,
  } = emails;
  const paginationArray = totalPage ? [...Array(totalPage)] : [];
  const commonClass = 'px-4 py-3 text-xs font-medium rounded-lg cursor-pointer shadow-xxl';
  const activeClass = 'text-white bg-themecolor';
  const inactiveClass = 'bg-white';
  return (
    <ul className="flex flex-wrap gap-2 mt-5">
      {paginationArray.length > 0 && paginationArray.map((m, index) => (
        <li
          key={index}
          onClick={() => setPage(index + 1)}
          className={`${commonClass} ${(index + 1) === page ? activeClass : inactiveClass}`}
        >
          {index + 1}
        </li>
      ))}
    </ul>
  );
};

export default Pagination;
