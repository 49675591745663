/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable eqeqeq */
// eslint-disable-next-line react/prop-types
const TemplateControllerTabItem = ({
  active, setActiveTab, currentIndex, label, icon
}) => (
  <div
    onClick={() => setActiveTab(currentIndex)}
    className={`p-5 flex text-center border-t-4 cursor-pointer border-transparent hover:bg-themegray-50 ${active == currentIndex ? 'bg-themegray-50 border-themecolor' : ''}`}
  >
    <strong className={`${active == currentIndex ? 'text-gray-800'
      : 'text-gray-400'}`}
    >
      {label}
    </strong>
    <img
      src={icon}
      className={`inline ml-1  ${active == currentIndex ? ''
        : 'opacity-60 filter grayscale'}`}
      alt="tab icon"
    />
  </div>
);

export default TemplateControllerTabItem;
